import { template as template_09ace43a4c3c455da73b12a9c0e68126 } from "@ember/template-compiler";
const SidebarSectionMessage = template_09ace43a4c3c455da73b12a9c0e68126(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
