import { template as template_f08f785eb7db4f5c9b6ce860152cc6b0 } from "@ember/template-compiler";
const FKControlMenuContainer = template_f08f785eb7db4f5c9b6ce860152cc6b0(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
